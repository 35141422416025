import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import axios from "axios";
import Selectrix from "react-selectrix";
import Modal from "react-bootstrap/Modal";

import "react-toastify/dist/ReactToastify.css";
import ReactCountryFlag from "react-country-flag";
import FlipCountdown from "@rumess/react-flip-countdown";
// import { GoRocket } from "react-icons/go";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from 'react-toastify';
// import TestimonialOne from "../../blocks/testimonial/TestimonialOne";
import Typical from 'react-typical';
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { FaRegCalendarAlt, FaWhatsapp } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
// import BrandTwo from "../../elements/BrandTwo";
import cx from "classnames";
import NonPassiveTouchTarget from "../NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import data2 from "../data2";
// import { AiOutlineDown, AiOutlineRight, AiOutlineClose } from "react-icons/ai";


const KeyCountry =  [
  {key: "PE", label: "Perú", countryNumber: "+51"},
  {key: "MX", label: "México", countryNumber: "+52"},
  {key: "BO", label: "Bolivia", countryNumber: "+591"},
  {key: "EC", label: "Ecuador", countryNumber: "+593"},
  {key: "SV", label: "El Salvador", countryNumber: "+503"},
  {key: "CR", label: "Costa Rica", countryNumber: "+506"},
  {key: "PR", label: "Puerto Rico", countryNumber: "+1"},
  {key: "HN", label: "Honduras", countryNumber: "+504"},
  {key: "UY", label: "Uruguay", countryNumber: "+598"},
  {key: "PY", label: "Paraguay", countryNumber: "+595"},
  {key: "GT", label: "Guatemala", countryNumber: "+502"},
  {key: "PA", label: "Panamá", countryNumber: "+507"},
  {key: "NI", label: "Nicaragua", countryNumber: "+505"},
  {key: "DO", label: "República Dominicana", countryNumber: "+1"},
  {key: "BR", label: "Brasil", countryNumber: "+55"},
  {key: "US", label: "Estados Unidos", countryNumber: "+1"}
]

const Container2 = touchWithMouseHOC(CarouselContainer2);

const cardSize = 300;
const cardSize2 = 280;
const cardPadCount = 2;
const carouselWidth = clamp(window.innerWidth, 0, 960);


function CarouselContainer2(props) {
  const {
    cursor,
    carouselState: { active, dragging },
    ...rest
  } = props;
  let current = -Math.round(cursor) % data2.length;
  while (current < 0) {
    current += data2.length;
  }
  // Put current card at center
  const translateX =
    (cursor - cardPadCount) * 300 + (carouselWidth - 300) / 2;
  return (
    <NonPassiveTouchTarget
      className={cx("carousel-container", {
        "is-active": active,
        "is-dragging": dragging,
      })}
      style={{height: "450px"}}
    >
      <NonPassiveTouchTarget
        className="carousel-track"
        style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
        {...rest}
      />

      <div className="carousel-pagination-wrapper">
        <ol className="carousel-pagination">
          {data2.map((_, index) => (
            <li key={index} className={current === index ? "current" : ""} />
          ))}
        </ol>
      </div>
    </NonPassiveTouchTarget>
  );
}

class FunnelBeta extends Component {
  constructor() {
    super();

    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    this.state = {
      pricingAPI: {},
      slugBootcamp: "",
      bootcampSlugMensual: "",
      Temario: "",
      Programa: "",
      isOpen: false,
      countryCode: "US",
      Email: "",
      Phone: "",
      Name: "",
      isOpenTemario: false,
      isPdfPrice: false,
      langUS: false,
      isPlaying: false,
      isOpenPlanPay: false,
      price_mensual_regular: "",
      price_completo_regular: "",
      price_completo: "",
      price_mensual: "",
      titlePricePre: "",
      demoTitle: "",
      demoUrl: "",
      demoPreview: "",
      fechaLimite:  '',
      dia:  '',
      DescuentoBody: "",
      visitasNumber: this.getRandomVisitasNumber(),
      videoRef: React.createRef(),
      isOpenLead: false,
      loadingEnroll: false,
      demo_alt: false,
      body_intro: false,
      select_mes: "",
      DataFechas: [],
      select_turno: "",
      isOpenResume: false,
      Turnos: [
        {key: "noche", label: "Noche"},
        {key: "tarde", label: "Tarde"},
    ],
      durationVideo: 0,
      durationPercen: 0,
      registerRequerid: false,
      videoInit: false,
      selectFecha: false,
      isModalWS: false,
      optionPlanPago: "0",
      showMore: false,
      showWeb: false,
      timeCounter: null,
      KeysPlanes: [
      ]
    };
    this.GetLinkWS = this.GetLinkWS.bind(this);
    this.GetLinkPay = this.GetLinkPay.bind(this);
    
    this.openApp = this.openApp.bind(this);
    this.openModalEmail = this.openModalEmail.bind(this);
    this.onRenderOption = this.onRenderOption.bind(this);
    this.DownloadPdf = this.DownloadPdf.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DynamicCountry = this.DynamicCountry.bind(this);
    
  }


  onRenderSelectionPais( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "#1f1f25"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2.3em',
                height: '2.3em',
                padding: '7px'
            }}
            title="país"
    />  {selected.countryNumber}
        </span>
        :
           <span style={{padding: 5}}>País</span>
    }
    
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}



  onRenderOptionPlan ( option, index ) {
    return (<li key={index} style={{padding: "10px", fontSize: "18px", fontWeight: "bold"}}>
            { option.label }
            </li>)
}



onRenderSelectionPlan( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: "10px" }}>
{ selected ?
        <span style={{"color": "#1f1f25", fontSize: "18px", fontWeight: "bold"}}>
           {selected.label}
        </span>
        :
           <span style={{padding: "10px"}}></span>
    }
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}


  // showMatrix(){
  //   // geting canvas by Boujjou Achraf
  //   var c = document.getElementById("dojo-mural");
  //   var ctx = c.getContext("2d");

  //   //making the canvas full screen
  //   c.height = window.innerHeight;
  //   c.width = window.innerWidth;

  //   //chinese characters - taken from the unicode charset
  //   var matrix = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}";
  //   //converting the string into an array of single characters
  //   matrix = matrix.split("");

  //   var font_size = 10;
  //   var columns = c.width/font_size; //number of columns for the rain
  //   //an array of drops - one per column
  //   var drops = [];
  //   //x below is the x coordinate
  //   //1 = y co-ordinate of the drop(same for every drop initially)
  //   for(var x = 0; x < columns; x++)
  //       drops[x] = 1; 

  //   //drawing the characters
  //   function draw()
  //   {
  //       //Black BG for the canvas
  //       //translucent BG to show trail
  //       ctx.fillStyle = "rgba(0, 0, 0, 0.01)";
  //       ctx.fillRect(0, 0, c.width, c.height);

  //       ctx.fillStyle = "#25D366";//green text
  //       ctx.font = font_size + "px arial";
  //       //looping over drops
  //       for(var i = 0; i < drops.length; i++)
  //       {
  //           //a random chinese character to print
  //           var text = matrix[Math.floor(Math.random()*matrix.length)];
  //           //x = i*font_size, y = value of drops[i]*font_size
  //           ctx.fillText(text, i*font_size, drops[i]*font_size);

  //           //sending the drop back to the top randomly after it has crossed the screen
  //           //adding a randomness to the reset to make the drops scattered on the Y axis
  //           if(drops[i]*font_size > c.height && Math.random() > 0.975)
  //               drops[i] = 0;

  //           //incrementing Y coordinate
  //           drops[i]++;
  //       }
  //   }

  //   setInterval(draw, 30);

  // }

  onSubmit(e) {
    // e.preventDefault();
    // if (this.state.Email.trim() === "") {
    //   toast.warning("El campo Email es requerido.");
    //   return;
    // }

    if (this.state.Email.trim() === "" || !this.state.Email.trim().includes("@") ) {
      toast.dark('¡Ups! El campo Email es necesario para continuar.');
      return;
    }

    // if (this.state.Phone.trim() === "" || this.state.Phone.length <= 5) {
    //   toast.dark('¡Ups! El campo WhatsApp es necesario para continuar.');
    //   return;
    // }


    this.setState({ loadingEnroll: true, sendDataForm: true });

    localStorage.setItem("email", this.state.Email || "");
    localStorage.setItem('name', this.state.Name);
    localStorage.setItem('phone', this.state.Phone  || "");

    window.fbq("track", "Contact");


    setTimeout(() => {
      const link = this.GetLinkWS();
      this.setState({loadingEnroll: false, isModalWS: true });
      window.open(link, '_blank').focus();
    }, 2000);

    const source = window.location.search.split('source=')[1] || '';


    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: "Web full stack",
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        phone: this.state.Phone,
        Token: "c3b4b89c",
        landing: window.location.pathname.includes("evento-online") ? "facebook-funnel": 'funnel-4',
        ticket: "" ,
        source: source,
      })
      .then((res) => {
        console.log("");
        // const link = this.GetLinkWS();
        // this.setState({loadingEnroll: false });
        // window.open(link, '_blank').focus();
      });
  }

  getRandomVisitasNumber() {
    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    return rango[Math.floor(Math.random() * rango.length)];
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  DynamicCountry(country = "") {

    if (country) {
      this.setState({ countryCode: country });

    }

    this.setState({
      isPricePais: false,
      utc_offset: "-0500",
      utc_dif: 0,
      price_mensual:
        this.state.pricingAPI[this.state.bootcampSlugMensual]["price"]["usd"][
          "price_view_final"
        ],
      price_mensual_regular:
        this.state.pricingAPI[this.state.bootcampSlugMensual]["price"]["usd"][
          "price_view_base_regular"
        ],
      titlePrice: this.state.pricingAPI[this.state.slugBootcamp]["titlePrice"],
      price_completo:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base"
        ], //495
      price_completo_regular:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base_regular"
        ],
        DataFechas: this.state.pricingAPI[this.state.slugBootcamp]['fechas'],
        select_mes:  this.state.pricingAPI[this.state.slugBootcamp]['fechas'][0]['mes'],
        select_turno: this.state.pricingAPI[this.state.slugBootcamp]['fechas'][0]['turno'],
    });

    try {
      if (this.state.pricingAPI[this.state.slugBootcamp].price[country]) {
        this.setState({
          isPricePais: true,
          price_completo:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][country][
              "price_view_base"
            ], //495
          price_completo_regular:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][country][
              "price_view_base_regular"
            ],
          price_mensual:
            this.state.pricingAPI[this.state.bootcampSlugMensual]["price"][
              country
            ]["price_view_final"],
          price_mensual_regular:
            this.state.pricingAPI[this.state.bootcampSlugMensual]["price"][
              country
            ]["price_view_base_regular"],
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  renderPrice(price, prueba = false) {
    return (
      <>
        <div className="row" style={{ placeContent: "center" }}>
          <div className="col-12">
            <p
              style={{
                fontSize: "45px",
                color: prueba ? "#1f1f25" : "white",
                fontWeight: "bold",
                paddingTop: "9px",
                paddingBottom: "9px",
              }}
            >
              {price}
              <span
                style={{
                  display: "inline-block",
                  fontSize: window.type_device ? "20px" : "25px",
                  marginLeft: "5px",
                }}
              >
                {" "}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }

  
  onRenderOption(option, complete) {
    return (
      <li>
        <ReactCountryFlag
          countryCode={option.key}
          svg
          style={{
            width: "2em",
            height: "2em",
            padding: "1px",
          }}
          title="país"
        />{" "}
        {complete ? option.label : option.key}
      </li>
    );
  }

  onRenderSelection(selected, complete) {
    return (
      <span style={{ padding: 2 }}>
        {selected ? (
          <span style={{ color: "#1f1f25" }}>
            <ReactCountryFlag
              countryCode={selected.key}
              svg
              style={{
                width: "2.3em",
                height: "2.3em",
                padding: "2px",
              }}
              title="país"
            />{" "}
            {complete ? selected.label : selected.key}
          </span>
        ) : (
          <span style={{ padding: 5 }}></span>
        )}
      </span>
    );
  }

  GetLinkWS() {

    if (window.type_device) {
      return `https://api.whatsapp.com/send?phone=51936670597&text=Hola, me gustaría confirmar mi registro y acceder a la clase online. ¡Gracias!`;
    } else {
      return `https://web.whatsapp.com/send?phone=51936670597&text=Hola, me gustaría confirmar mi registro y acceder a la clase online. ¡Gracias!`;
    }

    
  }

  GetLinkPay() {
    let linkVipPeru = "https://buy.stripe.com/";
    let linkVipUSD = "https://buy.stripe.com/";
    if (this.state.countryCode.toLocaleUpperCase() === "PE" ){
      return linkVipPeru
    } else {
      return linkVipUSD
    }
  }



  openModalEmail() {
    this.setState({ Phone: "" });
    this.setState({ isOpenTemario: !this.state.isOpenTemario });
  }

  openApp(){
    const link = this.GetLinkWS();
    window.open(link, '_blank').focus();
  }

  DownloadPdf(e) {
    e.preventDefault();

    if (this.state.Email === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    localStorage.setItem("email", this.state.Email);

    toast.success(
      !this.state.langUS
        ? "Hola!, enviamos el programa por Email, gracias!"
        : "Hello! We sent the program by Email, thank you!"
    );

    this.openModalEmail();

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: this.state.slugBootcamp,
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        Token: "c3b4b89c",
      })
      .then((res) => {
        window.gtag_report_conversion();
        // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
        // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
      });
  }


  onRenderOption ( option, complete ) {
    return (
        <li>
        <ReactCountryFlag
            countryCode={option.key}
            svg
            style={{
                width: '1.5em',
                height: '1.5em',
                padding: '1px'
            }}
            title="país"
        />  {option.key }
        </li>
            )
}

onRenderSelection( selected, complete ){
    return 	(<span style={{ padding: 2 }}>
    { selected ?
        <span style={{"color": "#1f1f25"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2em',
                height: '2em',
                padding: '2px'
            }}
            title="país"
    />
        </span>
        :
           <span style={{padding: 5}}></span>
    }
</span>)
}

renderCard2(index, modIndex) {
  const item = data2[modIndex];
  return (
      <div
      key={index}
      className="carousel-card"
      >
      <div style={{width: "275px"}}>
         <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "23px", "background": "white"}}>
              <h4>
              {item.title}
              </h4>
              <img alt={item.title} height="130px" src={item.background} />
          </div>
      </div>
      </div>
  );
  }


renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > Zona horaria </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
  
    />
        </>

    )
}




renderMes(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > {!this.state.langUS ? "Mes:": "Month:"} </span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={"Todos"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_mes}
        options={this.state.DataFechas.map((item) => ({key: item.mes, label: item.mes})) }
        onChange={value => this.setState({select_mes: value.key})}
    />
        </>

    )
}

renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{!this.state.langUS ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}

   
renderHorario(){
  if (this.state.countryCode === "PE" || this.state.countryCode === "EC" || this.state.countryCode === "CO") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 09:00 PM</p>
  } else if (this.state.countryCode === "MX" || this.state.countryCode === "HN" || this.state.countryCode === "CR") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">6:00 PM a 08:00 PM</p>
  } else if (this.state.countryCode === "BO"  || this.state.countryCode === "UY") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:00 PM</p>
  } else if (this.state.countryCode === "US"  || this.state.countryCode === "CA") {
    return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">11:00 AM a 13:00 PM</p>
  } else {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 09:00 PM</p>
  }
}


      renderHorarioFinSemana(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:35 AM</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 AM a 10:35 AM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 M</p>
        } else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:35 AM</p>
        }
    }

    renderHorarioFinSemanaTarde(){
        return (
        <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">05:00 PM a 7:35 PM</p>
        )
    }

renderFechaSelect(item, key){
    return (
        <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
        <div className="col-12 mb-3">

            <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                {item.fecha_inicio}
            </h2>
            <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

            <div className="row">
                <div className="col-lg-2 col-md-3 col-12  mt-2">
                <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                <FiVideo/> {!this.state.langUS ? "En vivo Gratuito": "Live"}
                                </span>
                </div>
                <div className="col-md-4 col-lg-3 col-12  mt-2">
                    <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        Plazas limitadas
                    </span>
                </div>
            </div>

        </div>

        <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Duración": "Duration"} </b></h5> </div>
            <div> <p style={{fontSize: "18px"}}> {!this.state.langUS ? "7 meses": "5 months"}
            <span style={{display: "inline-block"}}></span>
            </p> </div>
            <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
            <b>{item.fecha_start_end}</b>
            </span>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2"><b> {!this.state.langUS ? "Días": "Days"} </b></h5> </div>
            <div> <p style={{fontSize: "18px"}}> {item.dias}  </p> </div>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
            <div> <h5 className="mb-2"><b> {!this.state.langUS ? `Horario (${item.turno})`: `Schedule (${item.turno})`} </b></h5> </div>

            <div className="row">
                <div className="col-12">
                {(item.turno == 'noche' || item.turno == 'night') && this.renderHorario() }
                {item.turno == 'mañana'  && this.renderHorarioFinSemana() }
                {item.turno == 'tarde'  && this.renderHorarioFinSemanaTarde() }
                </div>

            </div>
        </div>

        <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
            <div>
            <a href={"#inscripcion"}>
            <button
            style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
            className=" btn-efect">
            ELEGIR FECHA
            </button>
            </a>
            </div>
        </div>

    </div>
    )
  }

renderFechas() {
    return (
        <div>
            {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                 this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                :
                this.renderFechaSelect(item, key)

            ))}

            {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
               :
                this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


            ))}
      </div>

    )}



  render() {
    const TypingAnimation2 =  React.memo(()=>{
      return !this.state.langUS ?
      <Typical
      steps={['Aprende 100% online en vivo', 500 ,'con la guía de tu Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"/>
      :
      <Typical
      steps={['Learn 100% online live', 500 ,'with the guidance of your Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"
  />
    },(props,prevProp)=> true ); // this line prevent re rendering
    

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Curso online - Primeros Pasos en el Desarrollo Web" />
        {/* End Pagehelmet  */}


  {this.state.timeCounter &&
     <div id="counter-deadline" className="pt-1 pb-2" >
        <h5 className="w-100 mt-2 mb-1 text-white text-center px-1" style={{fontSize: "17px"}} > 
            {this.state.porcentajeDescuento}
        </h5>
        <h5 className="my-0" style={{fontSize: "17px", color: "white", textAlign: "center"}} >
        ¡Aprovecha esta oferta! <span style={{color: "yellowgreen"}}>Paga solo $37 USD  </span> antes de que el tiempo se agote.
        </h5>

        <FlipCountdown
                            size={'small'}
                            yearTitle='Year'
                            dayTitle='Días'
                            hourTitle='Horas'
                            minuteTitle='Min'
                            secondTitle='Segs'
                            monthTitle=''
                            hideYear
                            hideMonth
                            hideDay
                            endAt={this.state.timeCounter?.toISOString()} // Date/Time
                            onTimeUp={() => {location.href = "/"}  }
                            />
    </div>
  }
    
        <div className="w-100 dojo-mural " >

        <div className="title-mural">

        <div  style={{paddingLeft: "1px",  paddingTop: "1px", textAlign: "center", maxWidth: "100%"}} >
    
    <div className="d-flex">
     <a href={'/unirme'}>
        <img style={{borderRadius: "50%"}} height="45px" class="logo-1" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
    </a>
    <div className="logo_dojopy" style={{color: "white", marginTop: "5px", marginBottom: "5px", fontFamily: "'El Messiri', sans-serif", lineHeight: "19px", "place-self": "center", "margin-left": "5px"}}>

<a href={'/unirme'} style={{color: "white"}}>
<span style={{color: "white", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "29px": "30px" }}>
  DOJO
</span>
<span style={{color: "white", marginLeft: "1px", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "13px": "14px", display: "block"}}>
  FULLSTACK
</span>
</a>
</div>
    </div>
    



    <div>
    
    </div>
 
      </div>

           <h1 data-aos="fade-top"  className="dojo-h1 pb-0 mb-1" style={{marginTop: this.state.timeCounter ? "50px": "10px", fontSize: window.type_device ? "35px": "50px", fontStyle: "italic", textAlign: "center", placeSelf: "center"}} >
           Primeros Pasos en el Desarrollo Web
            </h1>


            <h3 data-aos="fade-top"  className="dojo-h1 pb-4" style={{fontWeight: "normal", fontSize: "20px", fontStyle: "italic", textAlign: "center", placeSelf: "center"}} >
            
            De Cero a Full Stack: Tu Introducción al Desarrollo Web

            </h3>

        </div>

      </div>



<div>




<div className="about-wrapper" style={{paddingTop: window.type_device ? "5px": "50px", paddingBottom: "50px" }} id="webinar">




<div data-aos="fade-right"  className={window.type_device ? "container-fluid ": "container"}>

{ false && <div className="d-flex mt-3" style={{flexDirection: window.type_device ? "column": "row", gap: window.type_device ? "1px": "15px" }}>

<div className="d-flex mb-2" style={{maxWidth: "440px", border: "1px solid #25d366", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/3-face.jpg" />
    <div className="ml-2 mt-2">
      <h5 style={{fontStyle: "italic"}} className="my-0">Mark Zuckerberg
        <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}>CEO Facebook</span>
      </h5>
      <p style={{fontSize: "15px", fontStyle: "italic"}}>
        La gente siempre tiene miedo a lo desconocido. Pero si no te arriesgas a salir de tu zona de confort, nunca sabrás de lo que eres capaz.
        </p>
    </div>
  </div>

  <div className="d-flex mb-2" style={{border: "1px solid #25d366", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/1-face.webp" />
    <div className="ml-2 mt-2">
      <h5 style={{fontStyle: "italic"}} className="my-0">Elon Musk
        <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}>CEO Twitter & Paypal</span>
      </h5>
      <p style={{fontSize: "15px", fontStyle: "italic"}}>La vida es demasiado corta para ser pequeña. Tienes que hacer algo grande.</p>
    </div>
  </div>

  <div className="d-flex mb-2" style={{border: "1px solid #25d366", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/2-face.jpeg" />
    <div className="ml-2 mt-2">
      <h5 style={{fontStyle: "italic"}} className="my-0">Simón Borrero
        <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}>Founder Rappi</span>
      </h5>
      <p style={{fontSize: "15px", fontStyle: "italic"}}>
      La tecnología puede cambiar el mundo, pero el verdadero cambio lo generan las personas.
        </p>
    </div>
  </div>

</div> }

 

{/* 
<h2 className="dojo-h2 mt-3" style={{fontStyle: "italic", color: "#1f1f25", padding: window.type_device ? "5px 10px": "20px 70px"  }}>
Descubre un plan estructurado y comprobado para comenzar tu carrera como Desarrollador Full Stack Web y App desde cero, ¡sin necesidad de experiencia previa!
 Te guiaremos en cada paso del camino para que adquieras las habilidades necesarias y te sumerjas en el emocionante mundo del desarrollo Full Stack.
  </h2> */}

 

                     
<div className="inner">
    <div className="contact-form--1">
            <div className="row" data-aos="fade-right" >
                <div className="col-12 col-md-6"
                style={{paddingLeft: window.type_device ? "5px": "50px", paddingRight: window.type_device ? "5px": "50px"}}
                >
                {/* <h2 id="registro" className="title mb-2 mt-1 mx-3" style={{fontSize: window.type_device ? "45px": "50px" }} >
                ¡Sé Parte de la Revolución Digital!
                <div className=" mx-2" style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                </h2> */}

                {/* <span
                     style={{display: "inline-block", padding: "3px", marginLeft: "15px", "background-color": "tomato", "border-radius": "17px", "padding-left": "15px", "padding-right": "15px", "color": "white", "font-size": window.type_device ? "23px": "27px" }}>
                    Clase Online Gratuita
                    </span> */}



                    <div className="mx-2 mt-5" data-aos="fade-right">
                    <p className="mt-3 mb-2 p-2" style={{fontSize: "30px", fontWeight: "bold", fontFamily: '"Poppins",sans-serif'}}>
                    ¿Qué aprenderás?
                    </p>
                    <div className=" mx-2" style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Introducción al Desarrollo Full Stack Web
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    ¿Qué es el desarrollo Frontend y Backend?
                    </p>

              
                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Introducción a HTML, CSS Y JavaScript.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Tendencias y Oportunidades Laborales en el Desarrollo Web
                    </p>


                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                      <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                      Proyecto Final "Creación de una Tienda Virtual"
                    </p>


                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                      <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                      Despliegue de Aplicaciones Web en Entornos Cloud
                    </p>


                    </div>
                </div>




<div className="col-12 col-md-6" style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>

<p className="mt-3 mb-2 p-2" style={{fontSize: "30px", fontWeight: "bold", fontFamily: '"Poppins",sans-serif'}}>
¿Qué incluye?
</p>
<div className=" mx-2" style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>


<p style={{marginBottom: window.type_device ? "25px": "15px", fontSize: "20px"}}>
  <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
    Plataforma y comunidad disponible con acceso de por vida.

  <div className="col-12 mt-3">
                            <img style={{borderRadius: "15px"}}  alt="Practica con nuevos retos y actividades cada semana" height="150px" src="/assets/images/dojopy/plataforma_bootcamp.webp" />
                    </div>
</p>


<p style={{marginBottom: window.type_device ? "25px": "15px", fontSize: "20px"}}>
  <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
  Certificación 

  <div className="col-12 mt-3">
                            <img style={{borderRadius: "15px"}}  alt="" height="150px" src="/assets/images/dojopy/curso-online-intro-zero.png" />
                    </div>
</p>


<p style={{marginBottom: window.type_device ? "25px": "15px", fontSize: "20px"}}>
  <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
  
  CoPiloto de Programación con IA
  <span style={{color: "tomato"}}> Bonus especial por tiempo limitado: ¡actúa antes de que termine el contador de la página!
  </span>

  <div className="col-12 mt-3">
                            <img style={{borderRadius: "15px"}}  alt="" height="180px" src="/assets/images/dojopy/copyloto.png" />
                    </div>
</p>
</div>

               



<div className="container px-1 my-3"  >

                  <div style={{borderBottom: "1px solid gray"}}>

                  <h5 style={{fontSize: "19px", fontWeight: "bold", fontStyle: "italic"}}>
                  Programa valorado en: <del>$190 USD</del>  ¡Accede hoy y obtén un 80% de descuento!
                  </h5>

                  </div>
          
          <a href="https://buy.stripe.com/dR65l247ag2e8003de">
 <button
className="btn-efect-live mt-3"
  style={{color: "white", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{FontFamily: "'Poppins',sans-serif", fontSize: "25px"}}>
¡Accede hoy por solo! $37 USD
    </b> }

    </button>
    </a>
</div> 






                </div>
        </div>
        </div>
        




    </div>
  </div>

</div>




{/* {!window.type_device &&
    <div data-aos="fade-right" className="about-area bg_color--5 mb-5" id=""  >

<div className="container">
<div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title px-3">
                                            ¿Qué proyecto final nos espera?
                                            </h2>
                                            <h5 style={{fontStyle: "italic", fontSize: "18px"}}>
                                            "Restaurante Online Inspirado en Rappi"
                                            </h5>
                                        </div>
                                      
                                      <div className="px-3" style={{maxWidth: "650px"}}>
                                        <img width={"100%"} src="/assets/images/dojopy/ui-project.webp" />
                                      </div>
                                      </div>
                                      </div>
                            </div>
                    </div>
                </div>
} */}


<Modal aria-labelledby="contained-modal-title-vcenter"
      centered show={this.state.isModalWS} size={window.type_device ? "md": "lg"} onHide={() => this.setState({isModalWS: true})} style={{zIndex: "9999999999"}} >

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                          <h3 className=" p-1 text-dark" style={{fontSize: "25px", marginTop: window.type_device ? "1px": "20px"  }}>¡Gracias por registrarte! 🎉</h3>
                            
                          <p className="p-2 text-dark">
                             ¡Estamos emocionados de que quieras unirte a nuestra clase online!
                             Para confirmar tu registro y recibir el enlace de inmediato, por favor envíanos
                             un <b>mensaje de confirmación</b> por WhatsApp.
                            </p>
                          
                         

                            <div className="row w-100 m-1 text-center">

                        



                            <div className="col-12">

                            <button onClick={() => {
                                 const link = this.GetLinkWS();
                                 window.open(link, '_blank').focus();
                              }} className="btn-grupo-ws" style={{color: "black"}}>
                                <FaWhatsapp style={{marginRight: "5px", width: "28px", height: "28px", position: "relative", bottom: "3px"}}/>
                                Confirmar por WhatsApp
                                </button>
                            </div>



                                <p>
                                  
                                </p>
                            </div>

                           
                    </div>
                    </div>
                    </Modal.Body>
                </Modal>





    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                              ¿Para quién es este curso?
                                            </h2>

                                            <p>
                                            Este curso es perfecto si no tienes experiencia en programación y deseas iniciar una carrera en tecnología.
                                            Aprenderás de manera práctica las bases del desarrollo web, abriéndote a nuevas oportunidades laborales.
                                             ¡Es tu momento de comenzar en el mundo de Tech!

                                            </p>

                                        </div>

                                        <TouchCarousel
                                        component={Container2}
                                        cardSize={cardSize2}
                                        cardCount={data2.length}
                                        cardPadCount={cardPadCount}
                                        loop={true}
                                        autoplay={2e3}
                                        renderCard={this.renderCard2}
                                        /> 


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


 


<div className="container px-1 my-3"  >


<a href="https://buy.stripe.com/dR65l247ag2e8003de">
<button
className="btn-efect-live mt-3"
style={{color: "white", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{FontFamily: "'Poppins',sans-serif", fontSize: "21px"}}>
¡Quiero unirme al curso por solo $37 USD!
</b> }

</button>
</a>
</div> 




  {/* Start About Area */}
  <div className="about-area bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
{/*                             
                            <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Judith</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/alN6nU5a2Vo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>  
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Harold Ivan</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/CJWjmlO3_7k?si=0VWDgUmLhp4pyNRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                     */}

                              

                                {/* {!this.state.showMore &&
                                            <div className="col-12 text-center" style={{marginBottom: "80px"}}>
                                                <button
                                                onClick={() => this.setState({showMore: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "20px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>} */}



{this.state.showMore &&
                                  <>




                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Tatiana</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/NhOFeo9_rA4?si=_LWhcwSgAOlmF0Od" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Bladimir</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/dDPWdY-S8-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                           

                                  <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Stephany</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/330Wxw_z4_0?si=Ms_tnorjQRspKGrw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Ricardo Osorio</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/ZaR2-71Kg9Q?si=pz_7oW3sL6gOP2oG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Diana</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/gpLkbRA4ZrE?si=UV0v9lnJQn2IdwAA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                            <h3 className="title">Testimonio Paul</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/81xtrnKV9Ss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Oscar</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/qtu4qNnBZbI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Andrés</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/Xxif1Dc_ifQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                  </>

}
                              




                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




             









<ToastContainer
                position="bottom-center"
                autoClose={9000}
                zIndex={10000}
                />


      </React.Fragment>
    );
  }



  runtDiscountHotFunnel(){
    console.log("module runDiscountFlash!");

    const getCookieValue = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const discount60mValue = getCookieValue(`discount-funnel-temporal`);
    const discount24hValue = getCookieValue(`discount-funnel-reboot`);

    if (discount60mValue){
        console.log("DESCUENTO ACTIVO!");
        const discount60mDate = new Date(parseInt(discount60mValue, 10));
        // console.log(discount60mDate);
        this.setState({timeCounter: discount60mDate})
        return;
    }


    if ( !discount60mValue && !discount24hValue){
        console.log("CREANDO DESCUENTO!");

        const fechaActual = new Date();
        const fechaActual2 = new Date();

        const minutes = 60*4;
        const houresReboot = 12;

        fechaActual.setTime(fechaActual.getTime() +  (minutes * 60 * 1000) );
        fechaActual2.setTime(fechaActual2.getTime() +  (houresReboot * 60 * 60 * 1000) );

        document.cookie = `discount-funnel-temporal=${fechaActual.getTime()};expires=${fechaActual.toUTCString() }; path=/`;
        document.cookie = `discount-funnel-reboot=${fechaActual2.getTime()};expires=${fechaActual2.toUTCString() }; path=/`;
        this.setState({timeCounter: fechaActual})
    } else if (!discount60mValue && discount24hValue){
        console.log("FINALIZO EL DESCUENTO!");
        this.setState({showDeadLine: false, timeCounter: null});
        window.location.href = `/`;
    }
   
}

  async componentDidMount() {
    // document.getElementById('whatsapp').style.display='block';

    // window.urlWasi = this.GetLinkWS();

    // this.sleep(5000).then(r => {
    //   this.setState({showWeb: true});
    // })

    const pathdata = window.location.pathname;


    if (pathdata.includes("introduccion-payment")){
      // console.log("enviar evento pixel pago completado");
      window.fbq('track', 'Purchase', {
          value: 37,                 // Valor total de la compra
          currency: 'USD',               // Moneda utilizada (ISO 4217)
          content_type: 'product',       // Tipo de contenido
          content_ids: ['micro-course'],  // ID del producto o identificador
          content_name: 'micro-course'
      });
  }


    const respo = await axios.get(
      "https://edxcwnqqclvq7dccamuytezonu0hqrod.lambda-url.us-east-1.on.aws/"
    );
    const responseDemo = respo.data["evento"];

    const linkZoom = responseDemo.zoom;

    if (window.location.pathname.includes("zoom")){
      window.location.href = linkZoom;
      return;
    }

    this.setState({fechaLimite: responseDemo.fechaLimite, dia: responseDemo.dia});


    try {
      const responseIP = await axios.get("https://ipapi.co/json/");
      let dataPriceIP = responseIP.data;
      if (!dataPriceIP.country_code) throw "api error";
      this.setState({countryCode: dataPriceIP.country_code});

      // if (dataPriceIP.country_code === "PE"){
      //   this.setState({ KeysPlanes: [
      //     {"label": "Entrada Gratuita", "key": "0"},
      //     {"label": "💎 Acceso VIP (S/ 9)", "key": "1"}
      //   ] });
      // }


  } catch {
      axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/').then((response) => {
          let data = response.data;

          this.setState({countryCode: data.country_code});

          // if ( data.country_code === "PE"){
          //   this.setState({ KeysPlanes: [
          //     {"label": "Entrada Gratuita", "key": "0"},
          //     {"label": "💎 Acceso VIP (S/ 9)", "key": "1"}
          //   ] });
          // }

          }).catch((error) => {
              console.log("error api");
          });
  }


      // this.showMatrix();


    


      this.sleep(10000).then(r => {
        this.runtDiscountHotFunnel()
        // function obtenerValorAleatorio() {
        //   const valores = [50, 60, 120, 150, 160, 170, 180];
        //   const indiceAleatorio = Math.floor(Math.random() * valores.length);
        //   return valores[indiceAleatorio];
        // }
      
        // const minutes = obtenerValorAleatorio();

        //   const fechaActual = new Date();
        //   fechaActual.setTime(fechaActual.getTime() +  (minutes * 60 * 1000) );
        //   this.setState({timeCounter: fechaActual})
        
      })


  }
}

export default FunnelBeta;
